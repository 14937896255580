<template>
  <v-container>
    <carriers-table />
  </v-container>
</template>

<script>
import CarriersTable from "../components/CarriersTable.vue";

export default {
  name: "Home",
  components: {
    CarriersTable,
  },
};
</script>
