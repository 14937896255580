<template>
  <v-app>
    <v-app-bar app>
      <div class="d-flex align-center">
        <v-img
          v-if="this.$vuetify.theme.dark"
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo-dark.png"
          transition="scale-transition"
          width="120"
        />
        <v-img
          v-else
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="120"
        />
      </div>

      <v-spacer></v-spacer>
      <v-btn @click="themeSwitch()" text v-if="!$vuetify.breakpoint.xsOnly">
        <span class="mr-2" v-if="$vuetify.theme.dark">Light Mode</span>
        <span class="mr-2" v-if="!$vuetify.theme.dark">Dark Mode</span>
        <v-icon>mdi-brightness-4</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="$store.state.snackbar.show">
      {{ $store.state.snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    if (localStorage?.dark === "true") {
      this.$vuetify.theme.dark = true;
      localStorage.dark = "true";
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
  methods: {
    themeSwitch() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.dark = this.$vuetify.theme.dark + "";
    },
  },

  data: () => ({
    //
  }),
};
</script>
