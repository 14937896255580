import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";
import Vue from "vue";

Vue.use(VueApollo);

const apolloClient = new ApolloClient({
  uri: "https://graphql.annuitycheck.com/v1/graphql",
  headers: {
    "content-type": "application/json",
    "x-hasura-role": "admin",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImh0dHBzOi8vaGFzdXJhLmlvL2p3dC9jbGFpbXMiOnsieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJ1c2VyIiwiYWRtaW4iXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoiYWRtaW4iLCJ4LWhhc3VyYS11c2VyLWlkIjoiMSIsIngtaGFzdXJhLXJvbGUiOiJhZG1pbiJ9fQ.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYzMTMwODM0MiwianRpIjoiY2JhNTBhMzYtMTRmZS00MjA1LWI5ZjQtYzFiODNmNzlkMWZiIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6ImV5SnBaQ0k2SURFc0lDSmxiV0ZwYkNJNklDSnRZWFIwUUcxaGRIUm9ZWEp5YVhNdWRHVmphQ0lzSUNKbWFYSnpkRjl1WVcxbElqb2dJazFoZEhSb1pYY2lMQ0FpYkdGemRGOXVZVzFsSWpvZ0lraGhjbkpwY3lJc0lDSmhZMndpT2lBeE1IMD0iLCJuYmYiOjE2MzEzMDgzNDIsImh0dHBzOi8vaGFzdXJhLmlvL2p3dC9jbGFpbXMiOnsieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJ1c2VyIiwiYWRtaW4iXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoiYWRtaW4iLCJ4LWhhc3VyYS11c2VyLWlkIjoiMSIsIngtaGFzdXJhLXJvbGUiOiJhZG1pbiJ9fQ.XngvH3EqewjqTjVM5MGLtcv4bRchZMVIO0TWqkyfzcQ",
  },
});

export default new VueApollo({
  defaultClient: apolloClient,
});
